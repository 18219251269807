import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import StatelyForm from "./StatelyForm";

import { Button } from "@te-whatu-ora/anatomic";
import {
  OnboardingContext,
  OnboardingUserData,
} from "@context/onboardingContext";
import onboardingMachine from "@stateMachines/onboarding";

const Container = styled.div`
  padding-bottom: 40px;
`;

const TitleContainer = styled.div`
  margin-bottom: 74px;
  max-width: 414px;
`;

const Title = styled.h1`
  margin-bottom: 12px;
`;

const SubTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 394px;
`;

const OnboardingForm = ({
  onFinishForm,
  isFormReset,
}: {
  onFinishForm: () => void;
  isFormReset: boolean;
}) => {
  const [isFormFinished, setIsFormFinished] = useState(false);
  const finalStepRef = useRef<HTMLDivElement>(null);

  const { updateOnboardingData } = useContext(OnboardingContext) || {};

  useEffect(() => {
    if (isFormReset) {
      setIsFormFinished(false);
    }
  }, [isFormReset]);

  const handleFormFinished = useCallback(
    (context: { link: boolean; userData: OnboardingUserData }) => {
      setIsFormFinished(!!context.link);
      updateOnboardingData?.(context.userData);
    },
    [updateOnboardingData]
  );

  return (
    <Container ref={isFormFinished ? finalStepRef : null}>
      <TitleContainer>
        <Title>
          {isFormFinished
            ? "Thanks! You're all set up"
            : "Let's get you set up"}
        </Title>
        <SubTitle>
          {isFormFinished
            ? "Quickly and easily get up-to-date antibiotic advice, based on each patient's unique medical profile."
            : "This will only take 1 min and helps us to improve the guidelines."}
        </SubTitle>
      </TitleContainer>

      {isFormFinished ? (
        <Button onClick={onFinishForm}>Find a treatment recommendation</Button>
      ) : (
        <StatelyForm
          stateMachine={onboardingMachine}
          onFormFinished={handleFormFinished}
        />
      )}
    </Container>
  );
};

export default OnboardingForm;
