import axios from "axios";

interface FeedbackUserData {
  email?: string;
  recommendation: string;
  recommendationName: string;
  feedback: string;
  isMaoriPacifika: boolean;
  location: string;
  district: string;
  role: string;
  practiceArea: string;
}

interface FormValues {
  email?: string;
  feedback: string;
}

export interface FeedbackFormErrors {
  email: string;
  feedback: string;
}

export const postFeedback = async (userData: FeedbackUserData) => {
  // Replace with link to a serverless function
  const FEEDBACK_URL =
    "https://maker.ifttt.com/trigger/nzamg_feedback_sent/json/with/key/d3FPg7e3LuAWSGrZOw1yNc;";

  return await axios.post<FeedbackUserData>(FEEDBACK_URL, userData);
};

const validateForm = (values: FormValues): FeedbackFormErrors => {
  const errors: FeedbackFormErrors = {
    email: "",
    feedback: "",
  };

  if (!values.feedback?.trim()) {
    errors.feedback = "Please enter your feedback";
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  if (values.email && !emailRegex.test(values.email)) {
    errors.email = "Please enter a email address";
  }

  return errors;
};

export const getValidationError = (
  values: FormValues,
  field: keyof FeedbackFormErrors
): string => {
  const errors = validateForm(values);
  return errors[field];
};
